/* mada-200 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mada';
  font-style: normal;
  font-weight: 200;
  src: url('/assets/fonts/mada-v19-latin-200.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* mada-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mada';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/mada-v19-latin-300.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* mada-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mada';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/mada-v19-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* mada-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mada';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/mada-v19-latin-500.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* mada-600 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mada';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/mada-v19-latin-600.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* mada-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mada';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/mada-v19-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* mada-800 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mada';
  font-style: normal;
  font-weight: 800;
  src: url('/assets/fonts/mada-v19-latin-800.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* mada-900 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Mada';
  font-style: normal;
  font-weight: 900;
  src: url('/assets/fonts/mada-v19-latin-900.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-100 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('/assets/fonts/roboto-v30-latin-100.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-100italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: url('/assets/fonts/roboto-v30-latin-100italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/roboto-v30-latin-300.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-300italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('/assets/fonts/roboto-v30-latin-300italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/roboto-v30-latin-regular.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/roboto-v30-latin-italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-500 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/roboto-v30-latin-500.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-500italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('/assets/fonts/roboto-v30-latin-500italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/roboto-v30-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-700italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/roboto-v30-latin-700italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-900 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('/assets/fonts/roboto-v30-latin-900.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* roboto-900italic - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: url('/assets/fonts/roboto-v30-latin-900italic.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* material-icons-round-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/material-icons-round-v108-latin-regular.woff2')
    format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

//////////////////////////
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 200 900;
  font-stretch: 75%;
  font-display: swap;
  src: url('/assets/fonts/Inconsolata.woff2') format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

.inconsolata {
  font-family: 'Inconsolata', monospace;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: 'wdth' 75;
}
