@use 'sass:map';

@use '@angular/material' as mat;

// Imports

:root {
  --var-primary-900: #082f49;
  --var-primary-800: #0d3f5f;
  --var-primary-700: #0f4a6e;
  --mdc-filled-text-field-container-color: #1e293b;
  --mdc-dialog-container-color: theme(colors.slate.700);
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: #475569 !important;
  border-radius: 10px !important;
}
.mat-drawer-inner-container {
  overflow: hidden !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
  color: white !important;
}

.custom-title-field {
  .mat-mdc-form-field-infix {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 46px !important;
    min-height: 46px !important;
    line-height: 46px !important;
  }
}

.custom-field {
  .mat-mdc-form-field-infix {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 30px !important;
    min-height: 30px !important;
    line-height: 30px !important;
  }
}

.custom-field-with-icon {
  .mat-mdc-form-field-infix {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 45px !important;
    min-height: 45px !important;
    line-height: 45px !important;
  }

  .mdc-notched-outline {
    display: none;
  }
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background {
  background-color: #64748b !important;
  border: 0px !important;
  padding: 3px !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  background-color: #5fa5fa !important;
  border: 0px !important;
  padding: 3px !important;
}

.custom-progress-bar {
  --mdc-linear-progress-active-indicator-color: #800e0e !important;
}

.mat-mdc-progress-bar {
  background-color: #0f172a !important;
  --mdc-linear-progress-active-indicator-color: #7195cb !important;
}

.mat-horizontal-content-container {
  background-color: #0f172a;
  border-radius: 1rem;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.mat-vertical-content-container {
  background-color: #0f172a;
  border-radius: 1rem;
  margin-bottom: 10px !important;
  margin-left: 0px !important;
}

.mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header:not(:first-child)::before,
.mat-stepper-label-position-bottom
  .mat-horizontal-stepper-header:not(:last-child)::after {
  border: hidden !important;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  border: hidden !important;
}

.mat-stepper-vertical-line::before {
  border: hidden !important;
}

.mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
  background-color: #334155 !important;
}

.mat-horizontal-stepper-header-container {
  background-color: #334155 !important;
  border-radius: 15px !important;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}

@media (min-width: 800px) and (max-width: 1200px) {
  .mat-horizontal-stepper-header-container {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.mat-vertical-stepper-header {
  background-color: #334155 !important;
  border-radius: 15px !important;
  margin-bottom: 10px;
}

.mat-horizontal-stepper-header .mat-step-icon,
.mat-vertical-stepper-header .mat-step-icon {
  background-color: #0f172a !important;
  width: 35px !important;
  height: 35px !important;
  font-size: 20px !important;
}

.mat-vertical-stepper-header:last-child .mat-step-icon-state-done,
.mat-horizontal-stepper-header:last-child .mat-step-icon-state-done {
  background-color: #0f172a !important;
}

.mat-vertical-stepper-header .mat-step-icon-state-edit,
.mat-vertical-stepper-header:not(:last-child) .mat-step-icon-state-done,
.mat-horizontal-stepper-header .mat-step-icon-state-edit,
.mat-horizontal-stepper-header:not(:last-child) .mat-step-icon-state-done {
  background-color: #60a5fa !important;
}

.mat-step-icon .mat-icon {
  width: 23px !important;
  height: 23px !important;
  font-size: 22px !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #1e293b;
}

.mdc-switch:enabled .mdc-switch__track::after {
  background-color: gray !important;
  border-radius: 10px;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  color: #fafafa !important;
  background-color: #fafafa !important;
  border-radius: 10px;
}

.cdk-overlay-pane:not(.mat-mdc-select-panel-above)
  .mdc-menu-surface.mat-mdc-select-panel {
  background-color: #1e293b;
}

$slate-800: #1e293b;
$zinc-800: #27272a;
/** All the variables **/
// Font

// Colors
// Sky 900
$primary-color: #082f49;
$primary-color-darker: #082f49;
$primary-color-lighter: #0369a1;
$main-font-family: 'Roboto';

$warn-color: #ff0000;
$warn-color-lighter: #ff0000;
// Light Theme Text
$dark-text: #000000;
// Dark bg
// Zinc 800
$dark-background: $slate-800;

// Dark Theme Text
$light-text: #ffffff;

// Light bg
$light-background: #fafafa;

$mat-primary: (
  main: $primary-color,
  lighter: $primary-color-lighter,
  darker: $primary-color-darker,
  200: $primary-color,
  // For slide toggle,
  contrast:
    (
      main: $light-text,
      lighter: $dark-text,
      darker: $light-text,
    ),
);

$fontConfig: (
  display-4:
    mat.define-typography-level(112px, 112px, 300, $main-font-family, -0.0134em),
  display-3:
    mat.define-typography-level(56px, 56px, 400, $main-font-family, -0.0089em),
  display-2:
    mat.define-typography-level(45px, 48px, 400, $main-font-family, 0em),
  display-1:
    mat.define-typography-level(34px, 40px, 400, $main-font-family, 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, $main-font-family, 0em),
  title:
    mat.define-typography-level(20px, 32px, 500, $main-font-family, 0.0075em),
  subheading-2:
    mat.define-typography-level(16px, 28px, 400, $main-font-family, 0.0094em),
  subheading-1:
    mat.define-typography-level(15px, 24px, 500, $main-font-family, 0.0067em),
  body-2:
    mat.define-typography-level(14px, 24px, 500, $main-font-family, 0.0179em),
  body-1:
    mat.define-typography-level(14px, 20px, 400, $main-font-family, 0.0179em),
  button:
    mat.define-typography-level(14px, 14px, 500, $main-font-family, 0.0893em),
  caption:
    mat.define-typography-level(12px, 20px, 400, $main-font-family, 0.0333em),
  input:
    mat.define-typography-level(inherit, 1.125, 400, $main-font-family, 1.5px),
);

// Foreground Elements

$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text

$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten($dark-background, 20%);
$dark-bg-alpha-4: rgba($dark-background, 0.04);
$dark-bg-alpha-12: rgba($dark-background, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba($light-background, 0.04);
$light-bg-alpha-12: rgba($light-background, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
// @include mat.core($fontConfig);
@include mat.core();

// Theme Config

body {
  --primary-color: $primary-color;
  --primary-lighter-color: $primary-color-lighter;
  --primary-darker-color: $primary-color-darker;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}

$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #797979;
  --accent-lighter-color: #d7d7d7;
  --accent-darker-color: #5c5c5c;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
  main: #797979,
  lighter: #d7d7d7,
  darker: #5c5c5c,
  200: #797979,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: $warn-color;
  --warn-lighter-color: $warn-color-lighter;
  --warn-darker-color: $warn-color;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: $warn-color,
  lighter: $warn-color-lighter,
  darker: $warn-color,
  200: $warn-color,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);
// This is the dark theme
$theme: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  is-dark: true,
  foreground: $mat-dark-theme-foreground,
  background: $mat-dark-theme-background,
);

// This is the light theme
$lightTheme: mat.define-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
    // foreground: $mat-light-theme-foreground,
  )
);

$lightTheme: map.set($lightTheme, foreground, $mat-light-theme-foreground);

$lightTheme: map.set($lightTheme, background, $mat-light-theme-background);

// This is the dark theme
$darkTheme: mat.define-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: mat.define-typography-config(),
    density: 0,
    // foreground: $mat-light-theme-foreground,
  )
);

$darkTheme: map.set($darkTheme, foreground, $mat-dark-theme-foreground);

$darkTheme: map.set($darkTheme, background, $mat-dark-theme-background);

// Theme Init
@include mat.all-component-themes($darkTheme);

.theme-alternate {
  @include mat.all-component-colors($lightTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: white;
  --mdc-slider-active-track-color: white;
  --mdc-slider-inactive-track-color: darkgrey;
  --mdc-slider-focus-handle-color: white;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';

  .mat-badge-content {
    font-family: $main-font-family;
  }
}

.external-run-stepper {
  .mat-horizontal-stepper-header-container {
    width: 100%;
    margin-bottom: 25px !important;
  }

  .mat-horizontal-content-container {
    background-color: rgb(30 41 59);
    padding: 0 !important;
    margin-bottom: 0 !important;
  }

  .mat-step-header {
    pointer-events: none;
  }
}

.scan-input {
  .mat-mdc-text-field-wrapper {
    background-color: transparent !important;
  }

  .mdc-text-field {
    padding-left: 2px;
  }

  .mdc-text-field--outlined .mat-mdc-form-field-infix,
  .mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding-bottom: 6px;
    padding-top: 0px;
  }

  .mat-mdc-form-field-subscript-wrapper {
    height: 0px;
  }

  .mat-mdc-form-field-infix {
    min-height: 0px;
    width: 120px;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::before {
    border-bottom-color: black;
  }

  .mdc-text-field--filled .mdc-line-ripple::before {
    border-bottom-width: 2px;
  }
}

.custom-radio {
  .mdc-radio__inner-circle {
    border-color: #6db4fc !important;
  }

  .mdc-radio__outer-circle {
    border-color: #6db4fc !important;
  }
}

.scan-select-button {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: #334155;
  }

  .mdc-text-field--filled {
    border-radius: 5px;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::before {
    border-bottom: none;
  }

  .mat-mdc-form-field-infix {
    width: auto;
    padding-top: 2px;
    padding-bottom: 0px;
  }

  .mat-mdc-form-field-icon-prefix > .mat-icon {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  --mat-form-field-container-height: 45px;

  .mat-mdc-select-arrow-wrapper {
    transform: translateY(6px) !important;
  }

  .mat-mdc-select-value-text {
    margin-top: -2px;
    position: absolute;
  }
}

.scans-table {
  --mat-table-row-item-outline-width: 0px !important;
  --mat-table-row-item-container-height: 50px !important;
}

.scan-accordion {
  .mat-expansion-panel-body {
    padding: 0px !important;
  }

  .mat-expanded {
    .mat-expansion-indicator::after {
      transform: rotate(315deg) !important;
      background-color: #1e293b;
    }
  }

  .mat-expansion-indicator {
    rotate: 270deg !important;
    order: 0 !important;
  }

  @media (max-width: 640px) {
    --mdc-list-list-item-label-text-size: 12px !important;
  }
}

.mdc-dialog__surface {
  border-radius: 15px !important;
}

div.mat-mdc-select-panel {
  padding: 0px !important;
}

.report-table {
  --mat-table-row-item-outline-width: 0px;
}

/** Handle expansion panels in navigation bars */
mat-sidenav {
  &.collapsed {
    .mat-expansion-panel-header {
      padding: 0 !important;

      mat-icon {
        margin-left: 0;
        margin-right: 0;
        margin-left: 0 !important;
        margin-right: 0 !important;

        &::after {
          content: ' ';
          display: block;
          position: absolute;
          font-feature-settings: normal;
          font-size: 16px;
          font-weight: bolder;
          border-right: 2px solid;
          border-bottom: 2px solid;
          height: 7px;
          width: 7px;

          right: 50%;
          bottom: 0;
          transform: rotate(45deg) translate(0, -6px);
        }
      }
    }

    mat-panel-title {
      width: 100%;
    }

    .mat-expansion-panel-header-title {
      justify-content: center;
      margin-right: 0;
    }

    .mat-expansion-indicator {
      display: none;
    }

    .header-text {
      display: none;
    }
  }

  .mat-expansion-panel {
    border-radius: 0px !important;
    box-shadow: none !important;
    background: transparent;

    &.mat-expansion-panel-spacing {
      margin: 0px;
    }

    .mat-expansion-panel-body {
      padding: 0px;
    }

    .mat-expansion-panel-header {
      height: 56px;
      padding: 0px 24px 0px 0;
      background: transparent;

      mat-icon {
        margin-left: 16px;
        margin-right: 32px;
      }
    }

    .mat-expansion-panel-header-title {
      display: flex;
    }
  }
}

.report-inputs {
  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
    .mdc-list-item__primary-text {
    color: #ffffff !important;
  }

  .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate {
    background-color: #6db4fc !important;
  }

  .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after,
  .mat-pseudo-checkbox-full.mat-pseudo-checkbox-indeterminate::after {
    color: #64748b;
  }
}

.custom-badge .mat-badge-content {
  background-color: #6db4fc;
  color: #0f172a;
  font-weight: 700;
  right: -4px !important;
  top: -4px !important;
}

.scans-menu {
  .mat-mdc-menu-submenu-icon {
    visibility: hidden !important;
  }

  .mat-mdc-menu-item.mat-mdc-menu-item-submenu-trigger {
    padding-right: 16px !important;
  }
}

.custom-table {
  .p-datatable-header {
    padding-left: 0px;
    padding-right: 0px;
  }

  tbody:before {
    content: '\200C';
    display: block;
    line-height: 1rem;
    text-indent: -99999px;
  }

  thead tr th {
    background-color: #334155 !important;
    color: white;
    padding: 7px 6px 7px 6px;
  }

  thead tr th:first-of-type {
    border-radius: 5px 0 0 5px !important;
  }

  thead tr th:last-of-type {
    border-radius: 0 5px 5px 0 !important;
  }

  thead tr th div.header-cell {
    display: flex;
    flex-direction: row;
    background-color: #0f172a;
    border-radius: 5px;
    padding: 0px 6px 0px 6px;
    font-size: 14px;
    font-weight: 500;
    gap: 5px;
    justify-items: center;
    font-family: 'Mada' !important;
    height: 32px;
  }

  tbody tr {
    border: 12px solid #0f172a;
    border-collapse: separate;
    border-spacing: 4px;
  }

  tbody tr.row-collapsed {
    background-color: #0f172a;
  }

  tbody tr.row-collapsed:hover {
    background-color: #334155;
    transition: 0.3s;
  }

  tbody tr.row-expanded {
    background-color: #1e293b;
    border-bottom: 0px !important;
    transition: 0.3s;
  }

  tbody tr td {
    color: white;
    padding: 6px 12px 6px 20px;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Mada' !important;
    max-width: 200px !important;
  }

  tbody tr td:first-of-type {
    padding-left: 10px;
    cursor: pointer;
  }

  tbody tr.expanded-row td {
    background-color: #334155 !important;
    padding: 18px 12px 18px 12px;
    transition: 0.3s;
  }

  tbody tr.expanded-row {
    border-top: 0 !important;
  }

  input {
    background-color: #1e293b !important;
    height: 30px;
    width: 280px;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    font-size: 15px !important;
    font-weight: 600 !important;
    font-family: 'Mada' !important;
  }

  @media screen and (max-width: 1280px) {
    .p-datatable-thead,
    .p-datatable-tbody {
      display: block;
      width: 100%;
    }

    .p-datatable-thead > tr,
    .p-datatable-tbody > tr {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    .p-datatable-thead > tr > th,
    .p-datatable-tbody > tr > td {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 1 1 auto;
      flex-wrap: wrap;
      box-sizing: border-box;
    }

    .p-datatable-tbody > tr > td > .p-column-title {
      display: block;
    }

    .p-datatable-tbody > tr > td:not(:last-child) {
      border-bottom: 0 none;
    }

    thead tr th div.header-cell {
      width: 100%;
    }

    thead tr th:last-of-type {
      border-radius: 0 0 5px 0 !important;
    }

    thead tr th:first-of-type {
      border-radius: 5px 0 0 0 !important;
    }
  }

  @media screen and (max-width: 639px) {
    tr > th,
    tr > td {
      width: 100% !important;
    }

    tr > th.has-title:before,
    tr > td.has-title:before {
      content: attr(data-title) ': ';
      display: block;
      margin-right: 10px;
    }

    thead tr th:first-of-type {
      border-radius: 5px 5px 0 0 !important;
    }

    thead tr th:last-of-type {
      border-radius: 0 0 5px 5px !important;
    }
  }
}

.p-listbox-header {
  border-bottom: 1px solid #6db4fc66 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-bottom: 2px !important;
}

.p-ripple {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.filter-button {
  background-color: white !important;
  padding: 20px;
}

.p-column-filter-buttonbar {
  padding-top: 0px !important;
}
