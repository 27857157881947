@tailwind base;
@tailwind components;
@tailwind utilities;
@import './fonts.scss';

@import 'node_modules/shepherd.js/dist/css/shepherd.css';
@import 'primeng/resources/themes/lara-dark-blue/theme.css';
@import 'primeng/resources/primeng.css';
@import 'primeicons/primeicons.css';

@import './theme.scss';

@layer components {
  .box {
    @apply rounded-md dark:bg-slate-900 dark:text-zinc-500;
  }
}

@layer components {
  .back {
    @apply dark:bg-slate-800;
  }
}

.custom-placeholder {
  display: none;
}

//to change styling after select from autoFill at any input
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #1e293b;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #1e293b inset;
  box-shadow: 0 0 0px 1000px #1e293b inset;
  transition: background-color 5000s ease-in-out 0s;
  color: white;
}

* {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--seconderyColor);
  }
}

/* You can add global styles to this file, and also import other style files */
:root {
  --profilesBack: #d1d1d1;
  --successColor: #315335;
  --warningColor: #f2d2d5;
  --authBorderButtonColor: #0d6efd;
  --boxShadowColor: 0px 2px 10px rgba(34, 34, 34, 0.2);
  --authBoxShadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3),
    0 6px 20px 0 rgba(0, 0, 0, 0.3);
  --seconderyColor: #044469;
  --profileColorImagePadding: 3.33333px 7.33333px;
  --textsandIconColor: #aaaaaa;
  --textsandIconColorHover: #424242;
  --boxSearchingColor: rgba(3, 3, 3, 0.3);
  --profilesCreationDoneColor: #0886cd;
  --thirdColor: #555555;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #1e293b;
}

.mat-datepicker-content-container {
  background-color: #1e293b;
}

.truncate-text-start {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  direction: rtl;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'mada', Roboto, 'Helvetica Neue', sans-serif;
}

.highlightClass {
}

.shepherd-arrow::before {
  background: #334155;
}
