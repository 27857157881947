@use 'sass:map';
@use '@material/switch/switch-theme' as mdc-switch-theme;
@use '@material/form-field/form-field-theme' as mdc-form-field-theme;
@use '../core/style/sass-utils';
@use '../core/theming/theming';
@use '../core/theming/inspection';
@use '../core/typography/typography';
@use '../core/tokens/m2/mdc/form-field' as tokens-mdc-form-field;
@use '../core/tokens/m2/mdc/switch' as tokens-mdc-switch;

@mixin base($theme) {
  @if inspection.get-theme-version($theme) == 1 {
    @include _theme-from-tokens(inspection.get-theme-tokens($theme, base));
  }
  @else {
    @include sass-utils.current-selector-or-root() {
      @include mdc-switch-theme.theme(tokens-mdc-switch.get-unthemable-tokens());
    }
  }
}

@mixin color($theme) {
  @if inspection.get-theme-version($theme) == 1 {
    @include _theme-from-tokens(inspection.get-theme-tokens($theme, color));
  }
  @else {
    $is-dark: inspection.get-theme-type($theme) == dark;
    $mdc-switch-color-tokens: tokens-mdc-switch.get-color-tokens($theme);

    // Add values for MDC slide toggles tokens
    @include sass-utils.current-selector-or-root() {
      @include mdc-switch-theme.theme($mdc-switch-color-tokens);

      // TODO(wagnermaciel): Use our token system to define this css variable.
    --mdc-switch-disabled-label-text-color: #{inspection.get-theme-color(
      $theme,
      foreground,
      disabled-text
    )};

    .mat-mdc-slide-toggle {
        @include mdc-form-field-theme.theme(tokens-mdc-form-field.get-color-tokens($theme));

        // Change the color palette related tokens to accent or warn if applicable
        &.mat-accent {
          @include mdc-switch-theme.theme(
              tokens-mdc-switch.private-get-color-palette-color-tokens($theme, accent));
        }

        &.mat-warn {
          @include mdc-switch-theme.theme(
              tokens-mdc-switch.private-get-color-palette-color-tokens($theme, warn));
        }
      }
    }
  }
}

@mixin typography($theme) {
  @if inspection.get-theme-version($theme) == 1 {
    @include _theme-from-tokens(inspection.get-theme-tokens($theme, typography));
  }
  @else {
    $mdc-switch-typography-tokens: tokens-mdc-switch.get-typography-tokens($theme);

    // Add values for MDC slide toggle tokens
    @include sass-utils.current-selector-or-root() {
      @include mdc-switch-theme.theme($mdc-switch-typography-tokens);

      .mat-mdc-slide-toggle {
        @include mdc-form-field-theme.theme(tokens-mdc-form-field.get-typography-tokens($theme));
      }
    }
  }
}

@mixin density($theme) {
  @if inspection.get-theme-version($theme) == 1 {
    @include _theme-from-tokens(inspection.get-theme-tokens($theme, density));
  }
  @else {
    @include sass-utils.current-selector-or-root() {
      @include mdc-switch-theme.theme(tokens-mdc-switch.get-density-tokens($theme));
    }
  }
}

@mixin theme($theme) {
  @include theming.private-check-duplicate-theme-styles($theme, 'mat-slide-toggle') {
    @if inspection.get-theme-version($theme) == 1 {
      @include _theme-from-tokens(inspection.get-theme-tokens($theme));
    }
    @else {
      @include base($theme);
      @if inspection.theme-has($theme, color) {
        @include color($theme);
      }
      @if inspection.theme-has($theme, density) {
        @include density($theme);
      }
      @if inspection.theme-has($theme, typography) {
        @include typography($theme);
      }
    }
  }
}

@mixin _theme-from-tokens($tokens) {
  @if ($tokens != ()) {
    @include mdc-switch-theme.theme(map.get($tokens, tokens-mdc-switch.$prefix));
  }
}
